import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { ReactComponent as Logo } from '../logo.svg';
import { MdMenu, MdClose } from 'react-icons/md';
//import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

const items = [
	{
		id: 0,
		title: 'Get Schedulist',
		url: '/#productivity-reinvented',
	},
	{
		id: 1,
		title: 'Features',
		url: '/#one-daily-agenda',
	},
	{
		id: 2,
		title: 'Blog',
		url: 'https://blog.schedulist.app',
	},
	{
		id: 3,
		title: 'About',
		url: '/about',
	},
]

const Header = styled(({ className }) => {
	const [toggleNav, setToggleNav] = useState(false);

	useEffect(() => {
		function handleEscapeKey(event: KeyboardEvent) {
		  if (event.code === 'Escape') {
			setToggleNav(false)
		  }
		}
	  
		document.addEventListener('keydown', handleEscapeKey)
		return () => document.removeEventListener('keydown', handleEscapeKey)
	}, [])

	return (
		<div
			className={classNames(
				className,
				'header absolute px-8 lg:fixed top-0 left-0 right-0 flex flex-row items-center justify-between h-24 md:h-28 z-10',
				{ active: toggleNav },
			)}
		>
			<nav className="relative z-10 py-2 lg:py- w-full">
				<div className="container px-0 lg:px-0 mx-auto lg:flex lg:items-center">
					<div className="flex justify-between items-center">
						<Link to="/" className="brand">
							<Logo className="h-40 w-40 md:h-48 md:w-48 z-20" />
						</Link>
						<button
							className={classNames(
								'fixed lg:relative top-4 right-4 z-10 px-2 py-1 text-gray-600 focus:outline-none focus-visible:ring-0 lg:hidden',
								{ active: toggleNav },
							)}
							onClick={() => setToggleNav(!toggleNav)}
						>
							{toggleNav ? <MdClose size={24} /> : <MdMenu size={24} />}
						</button>
					</div>

					<div
						className={classNames(
							toggleNav ? 'flex' : 'hidden',
							'flex flex-col lg:flex-row lg:ml-auto mt-3 lg:mt-0 lg:visible lg:flex navbar-collapse',
						)}
					>
						<div
							className={classNames(
								'bg-black bg-opacity-75 lg:bg-transparent fixed lg:absolute top-0 lg:top-0 left-0 lg:left-auto right-0 bottom-0 lg:bottom-0 h-screen lg:h-auto w-full lg:w-auto items-center flex justify-center flex-col lg:flex-row z-8',
							)}
						>
							{items.map((item) => {
								if(item.url.startsWith('http')){
									return <a
										key={item.id}
										href={item.url}
										className="p-2 py-8 lg:py-2 lg:p-2 lg:px-4 lg:mx-2 text-2xl lg:text-base font-semibold  lg:font-normal leading-normal text-gray-100 lg:text-gray-400 rounded hover:text-gray-100 transition-colors duration-300"
									>
										{item.title}
									</a>
								}
								return (
									<Link
										key={item.id}
										to={item.url}
										className="p-2 py-8 lg:py-2 lg:p-2 lg:px-4 lg:mx-2 text-2xl lg:text-base font-semibold  lg:font-normal leading-normal text-gray-100 lg:text-gray-400 rounded hover:text-gray-100 transition-colors duration-300"
										onClick={() => setToggleNav(!toggleNav)}
									>
										{item.title}
									</Link>
								);
							})}
						</div>
					</div>
				</div>
			</nav>
		</div>
	);
})`
	button {
		background: rgba(0,0,0,0.3);
		border-radius: 4px;
		svg {
			fill: rgba(255,255,255,0.7);
		}
		&:hover {
			svg {
				fill: rgba(255,255,255,1);
			}
		}
	}

	&.active {
		.brand {
			z-index: 2;
		}
	}

	.navbar-collapse {

		@media only screen and (orientation:landscape) {
			> div {

			}
			p {
				padding: 16px 0;
			}
		}

		@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
			> div {
				backdrop-filter: blur(2px);
				padding-top: 90px;
			}
			p {
				padding: 16px 0;
			}
		}
	}
`;

export default Header;
